<template>
    <Overlay @fechaOverlay="$emit('closeModal')">
        <Card>
            <div class="modal">
                <img v-if="isError" class="error" src="../assets/images/error.svg" alt="" />
                <img v-else class="success" src="../assets/images/done.svg" alt="" />

                <h4 v-if="isError">Erro</h4>
                <h4 v-else>Sucesso</h4>

                <p>{{ mensagem }}</p>

                <button type="button" :class="[isError ? 'error' : 'success']" @click="$emit('closeModal')">Fechar</button>
            </div>
        </Card>
    </Overlay>
</template>

<script>
import Overlay from './UI/Overlay.vue';
import Card from './UI/Card.vue';

export default {
    components: {
        Overlay,
        Card,
    },

    props: {
        mensagem: {
            required: true,
            type: String,
        },
        isError: {
            required: true,
            type: Boolean,
        },
    },
};
</script>

<style lang="scss" scoped>
.modal {
    width: 220px;
    display: grid;
    gap: 15px;
    text-align: center;

    h4 {
        font-size: 1.8rem;
        color: #454545;

        @media (min-width: 768px) {
            font-size: 2.2rem;
        }
    }

    p {
        color: #565656;
        letter-spacing: 0.5px;
        font-size: 1.1rem;
    }

    img {
        width: 150px;
        justify-self: center;
        border: 2px solid;
        border-radius: 50%;

        &.error {
            border-color: #f72a2a;
        }

        &.success {
            border-color: #1a961a;
        }
    }

    button {
        border: none;
        background-color: transparent;
        font-size: 1.2rem;
        cursor: pointer;
        padding: 10px 0;
        border-radius: 25px;
        transition: 0.2s ease-in-out;

        &.error {
            color: #fff;
            background-color: #f72a2a;

            &:hover {
                background-color: darken($color: #f72a2a, $amount: 25);
            }
        }

        &.success {
            background-color: #1a961a;
            color: #fff;

            &:hover {
                background-color: darken($color: #1a961a, $amount: 25);
            }
        }
    }

    @media (min-width: 768px) {
        width: 300px;
    }
}
</style>
