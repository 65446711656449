<template>
    <div>
        <div class="overlay" @click="$emit('fechaOverlay')" />
        <div class="overlay_centered">
            <slot />
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.7);

    &_centered {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 900;
    }
}
</style>
