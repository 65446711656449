<template>
    <Overlay @fechaOverlay="$emit('fechaOverlay')">
        <Card>
            <form class="form" @submit.prevent="salvaEdicao()">
                <div>
                    <label for="categoria_regras_rebaixa">Categoria:</label>
                    <input type="text" id="categoria_regras_rebaixa" name="categoria_regras_rebaixa" :value="categoria_regras_rebaixa" disabled />
                </div>

                <div>
                    <label for="regra_rebaixa">Regra:</label>
                    <input type="text" id="regra_rebaixa" name="regra_rebaixa" :value="regra_rebaixa" disabled />
                    <!-- <select name="regra_rebaixa" id="regra_rebaixa" @change="regraHandler">
                        <option
                            v-for="tipo in tipos"
                            :key="tipo.regra"
                            :value="tipo.regra"
                            :selected="tipo.regra == editRegraRebaixa"
                        >
                            {{ tipo.regra }}
                        </option>
                    </select> -->
                </div>

                <div v-if="referencia">
                    <label for="referencia">Referência:</label>
                    <input type="text" id="referencia" name="referencia" :value="referencia" disabled />
                </div>

                <div>
                    <div :class="{ error: tempoMinimoInvalido }">
                        <label for="tempo_minimo">Início:</label>
                        <input
                            type="number"
                            id="tempo_minimo"
                            name="tempo_minimo"
                            v-model="editTempoMinimo"
                            min="0"
                            max="75"
                        />
                    </div>

                    <div :class="{ error: tempoMaximoInvalido }">
                        <label for="tempo_maximo">Fim:</label>
                        <input
                            type="number"
                            id="tempo_maximo"
                            name="tempo_maximo"
                            v-model="editTempoMaximo"
                            min="0"
                            max="75"
                        />
                    </div>
                </div>

                <small class="form_error-msg" v-if="tempoMinimoInvalido">Tempo Mínimo não pode estar vazio.</small>
                <small class="form_error-msg" v-if="tempoMaximoInvalido">Tempo Máximo não pode estar vazio.</small>

                <Button texto="Salvar" />
            </form>
        </Card>
    </Overlay>
</template>

<script>
import Overlay from '../UI/Overlay.vue';
import Card from '../UI/Card.vue';
import Button from '../UI/Button.vue';

export default {
    components: {
        Overlay,
        Card,
        Button,
    },

    props: {
        categoria_regras_rebaixa: {
            required: true,
        },
        tempo_minimo: {
            required: true,
        },
        tempo_maximo: {
            required: true,
        },
        regra_rebaixa: {
            required: true,
        },
        referencia: {
            required: true,
        },
        tipos: {
            required: true,
        },
    },

    data() {
        return {
            tempoMinimoInvalido: false,
            tempoMaximoInvalido: false,
            editRegraRebaixa: '',
            editTempoMinimo: '',
            editTempoMaximo: '',
        };
    },

    created() {
        this.editTempoMinimo = this.tempo_minimo;
        this.editTempoMaximo = this.tempo_maximo;
        this.editRegraRebaixa = this.regra_rebaixa;
    },

    watch: {
        editTempoMinimo(newValue) {
            if (newValue != '') {
                this.tempoMinimoInvalido = false;
            }
        },
        editTempoMaximo(newValue) {
            if (newValue != '') {
                this.tempoMaximoInvalido = false;
            }
        },
    },

    methods: {
        regraHandler(event) {
            this.editRegraRebaixa = event.target.value;
        },

        salvaEdicao() {
            if (this.validaEdicao() == false) return;

            const regra = {
                categoria_regras_rebaixa: this.categoria_regras_rebaixa,
                tempo_minimo: this.editTempoMinimo,
                tempo_maximo: this.editTempoMaximo,
                regra_rebaixa: this.editRegraRebaixa,
                referencia: this.referencia,
            };

            this.$emit('salvaEdicao', regra);
        },

        validaEdicao() {
            if (this.editTempoMinimo == '') {
                this.tempoMinimoInvalido = true;
                return false;
            }

            if (this.editTempoMaximo == '') {
                this.tempoMaximoInvalido = true;
                return false;
            }

            return true;
        },
    },
};
</script>

<style lang="scss" scoped>
.checkbox {
    display: block;

    p {
        font-size: 1.2rem;
        margin-bottom: 20px;
    }

    &_categorias {
        display: grid;
        gap: 15px;
        font-size: 1rem;
        height: 250px;
        overflow-y: auto;
        overflow-x: hidden;

        @media (min-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    input {
        width: auto;
    }

    &_wrap {
        justify-content: unset;
    }
}
</style>
