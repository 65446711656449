<template>
    <div class="card">
        <slot />
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.card {
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 0 3px #696969;
    border-radius: 10px;
    height: fit-content;

    @media (min-width: 1400px) {
        padding: 20px;
    }
}
</style>
