<template>
    <div class="spinner">
        <img src="../../assets/images/spinner.gif" alt="" />
    </div>
</template>

<script>
export default {};
</script>

<style lang='scss' scoped>
.spinner {
    margin: 100px 0;
    text-align: center;
}
</style>
