<template>
    <div class="container">
        <Title titulo="Regras Rebaixa" />

        <div class="navigate">
            <SearchInput texto="Pesquisar categoria" @changeInput="searchText" />

            <Button texto="Nova Regra" @click="isCreating = true" />
        </div>

        <Spinner v-if="isLoading" />
        <template v-else>
            <div class="paginate">
                <div class="paginate_results">
                    {{ (currentPage - 1) * itensPerPage }} -
                    {{ currentPage * itensPerPage >= totalRegras ? totalRegras : currentPage * itensPerPage }} de
                    {{ totalRegras }} resultados
                </div>

                <div class="paginate_buttons">
                    <div>
                        <button @click="prevPageHandler">
                            <img src="../assets/images/chevron-left.svg" alt="left arrow" />
                        </button>
                    </div>

                    <div>{{ currentPage }} de {{ totalPages }}</div>

                    <div>
                        <button @click="nextPageHandler">
                            <img src="../assets/images/chevron-right.svg" alt="right arrow" />
                        </button>
                    </div>
                </div>
            </div>

            <div class="regras">
                <transition-group name="fade" mode="out-in">
                    <RegraItem
                        v-for="(regra, index) in regras"
                        :key="index"
                        :categoria_regras_rebaixa="regra.categoria_regras_rebaixa"
                        :referencia="regra.referencia"
                        :regra_rebaixa="regra.regra_rebaixa"
                        :tempo_minimo="regra.tempo_minimo"
                        :tempo_maximo="regra.tempo_maximo"
                        @editarRegra="editarRegra"
                    />
                </transition-group>
            </div>
        </template>

        <transition name="fade" mode="out-in">
            <EditaRegra
                v-if="isEditing"
                @fechaOverlay="fechaOverlay"
                @salvaEdicao="salvaEdicao"
                :categoria_regras_rebaixa="categoria_regras_rebaixa"
                :tempo_minimo="tempo_minimo"
                :tempo_maximo="tempo_maximo"
                :regra_rebaixa="regra_rebaixa"
                :referencia="referencia"
                :tipos="tipos"
            />
        </transition>

        <transition name="fade" mode="out-in">
            <NovaRegra
                v-if="isCreating"
                :tipos="tipos"
                :referencias="referencias"
                @fechaOverlay="fechaOverlay()"
                @salvaRegra="salvaRegra"
            />
        </transition>

        <Modal v-if="showModal" :isError="isError" :mensagem="modalMessage" @closeModal="showModal = false" />
    </div>
</template>

<script>
import Title from '../components/UI/Title.vue';
import SearchInput from '../components/SearchInput.vue';
import Button from '../components/UI/Button.vue';
import Modal from '../components/Modal.vue';
import Spinner from '../components/UI/Spinner.vue';
import RegraItem from '../components/RegrasVarejo/RegraItem.vue';
import EditaRegra from '../components/RegrasVarejo/EditaRegra.vue';
import NovaRegra from '../components/RegrasVarejo/NovaRegra.vue';
import axios from 'axios';

export default {
    components: {
        Title,
        SearchInput,
        Button,
        Modal,
        Spinner,
        RegraItem,
        EditaRegra,
        NovaRegra,
    },

    data() {
        return {
            categoria_regras_rebaixa: '',
            tempo_minimo: '',
            tempo_maximo: '',
            regra_rebaixa: '',
            referencia: '',
            id_categoria_regra: '',
            isEditing: false,
            isCreating: false,
            todasRegras: [],
            regrasFiltradas: [],
            showModal: false,
            isError: false,
            modalMessage: '',
            isLoading: true,
            regras: [],
            headers: null,
            totalPages: null,
            currentPage: 1,
            itensPerPage: 50,
            totalRegras: null,
            tipos: [],
            referencias: [],
            textoFiltrado: '',
        };
    },

    computed: {
        nextPage() {
            return parseInt(this.currentPage) + 1;
        },
        prevPage() {
            return this.currentPage - 1;
        },
    },

    beforeRouteEnter(to, from, next) {
        const permissoes = JSON.parse(sessionStorage.getItem('permissoes'));

        if (permissoes.permissoes.tela_rebaixas == 0) {
            next({ path: '/usuarios' });
        } else {
            next();
        }
    },

    created() {
        this.user = JSON.parse(sessionStorage.getItem('user'));

        this.headers = { Accept: 'application/json', authorization: 'Bearer ' + this.user.token };

        const requests = [
            this.axios.get(`${this.user.cliente}/regras`, { headers: this.headers }),
            this.axios.get(`${this.user.cliente}/regras/tipos`, { headers: this.headers }),
            this.axios.get(`${this.user.cliente}/regras/referencias`, { headers: this.headers }),
        ];

        axios
            .all(requests)
            .then(
                axios.spread((regras, tipos, referencias) => {
                    this.todasRegras = regras.data;
                    this.tipos = tipos.data;
                    this.referencias = referencias.data;

                    this.getRegrasPages(this.todasRegras);
                })
            )
            .catch(() => {
                this.isError = true;
                this.showModal = true;
                this.modalMessage =
                    'Sua solicitação não pôde ser realizada no momento, tente novamente mais tarde, ou caso o erro persista entre em contato com o suporte.';
            })
            .finally(() => {
                this.isLoading = false;
            });
    },

    beforeRouteUpdate(to) {
        if (this.regrasFiltradas != '') {
            this.getRegrasPages(this.regrasFiltradas, to.params.page);
        } else {
            this.getRegrasPages(this.todasRegras, to.params.page);
        }
    },

    methods: {
        nextPageHandler() {
            if (this.nextPage <= this.totalPages) {
                this.$router.push({ path: '/rebaixas/' + this.nextPage });
            }
        },

        prevPageHandler() {
            if (this.prevPage > 0) {
                this.$router.push({ path: '/rebaixas/' + this.prevPage });
            }
        },

        editarRegra(regra) {
            this.categoria_regras_rebaixa = regra.categoria_regras_rebaixa;
            this.tempo_minimo = regra.tempo_minimo;
            this.tempo_maximo = regra.tempo_maximo;
            this.regra_rebaixa = regra.regra_rebaixa;
            this.referencia = regra.referencia;
            this.id_categoria_regra = regra.id_categoria_regra;

            this.isEditing = true;
        },

        salvaEdicao(regra) {
            this.axios
                .patch(`${this.user.cliente}/regras`, regra, { headers: this.headers })
                .then(() => {
                    const existingRegraIndex = this.todasRegras.findIndex(
                        (item) =>
                            item.categoria_regras_rebaixa == regra.categoria_regras_rebaixa &&
                            item.regra_rebaixa == regra.regra_rebaixa
                    );

                    let updatedRegra = [...this.todasRegras];
                    updatedRegra[existingRegraIndex] = regra;

                    this.todasRegras = updatedRegra;

                    const existingRegraFiltradaIndex = this.regrasFiltradas.findIndex(
                        (item) =>
                            item.categoria_regras_rebaixa == regra.categoria_regras_rebaixa &&
                            item.regra_rebaixa == regra.regra_rebaixa
                    );

                    let updatedRegraFiltrada = [...this.regrasFiltradas];
                    updatedRegraFiltrada[existingRegraFiltradaIndex] = regra;

                    this.regrasFiltradas = updatedRegraFiltrada;

                    this.getRegrasPages(this.regrasFiltradas, this.$route.params.page);

                    this.isError = false;
                    this.modalMessage = 'Regra da rebaixa editada com sucesso.';
                })
                .catch((error) => {
                    const response = error.response;
                    this.isError = true;

                    if (response.status == 406) {
                        this.modalMessage = response.data;
                    } else {
                        this.modalMessage =
                            'Sua solicitação não pôde ser realizada no momento, tente novamente mais tarde, ou caso o erro persista entre em contato com o suporte.';
                    }
                })
                .finally(() => {
                    this.isEditing = false;
                    this.showModal = true;
                });
        },

        salvaRegra(regra) {
            this.axios
                .post(`${this.user.cliente}/regras`, regra, { headers: this.headers })
                .then(() => {
                    this.todasRegras.push(regra);
                    this.regrasFiltradas.push(regra);

                    this.getRegrasPages(this.regrasFiltradas, this.$route.params.page);

                    this.isError = false;
                    this.modalMessage = 'Regra cadastrada com sucesso.';
                })
                .catch((error) => {
                    const response = error.response;
                    this.isError = true;

                    if (response.status == 406) {
                        this.modalMessage = response.data;
                    } else {
                        this.modalMessage =
                            'Sua solicitação não pôde ser realizada no momento, tente novamente mais tarde, ou caso o erro persista entre em contato com o suporte.';
                    }
                })
                .finally(() => {
                    this.isCreating = false;
                    this.showModal = true;
                });
        },

        getRegrasPages(regras, pageFilter = '') {
            let page;

            if (pageFilter) {
                page = pageFilter;
            } else {
                page = this.$route.params.page;

                if (!page) {
                    page = 1;
                }
            }

            this.totalRegras = regras.length;
            this.totalPages = Math.ceil(regras.length / this.itensPerPage);

            this.currentPage = page;

            const min = (page - 1) * this.itensPerPage;
            const max = this.itensPerPage * page;

            let items = regras.slice(min, max);

            this.regras = items;
        },

        fechaOverlay() {
            this.isEditing = false;
            this.isCreating = false;
            this.showModal = false;
        },

        searchText(text) {
            this.regrasFiltradas = [];

            this.textoFiltrado = text;

            if (text.trim().length > 0) {
                const produtosFiltrados = this.todasRegras.filter((regra) => {
                    return regra.categoria_regras_rebaixa.toString().includes(text);
                });

                this.regrasFiltradas = produtosFiltrados;

                this.getRegrasPages(produtosFiltrados, 1);
            } else {
                this.getRegrasPages(this.todasRegras, this.$route.params.page);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.navigate {
    display: grid;
    gap: 30px;
    margin-bottom: 50px;

    @media (min-width: 768px) {
        gap: 50px;
        grid-template-columns: 350px 150px 150px;
    }
}

.regras {
    display: grid;
    gap: 30px;

    @media (min-width: 768px) {
        gap: 30px;
        justify-content: space-between;
        grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1600px) {
        gap: 50px;
        grid-template-columns: repeat(4, 1fr);
    }
}

.paginate {
    display: grid;
    gap: 20px;
    margin-bottom: 30px;

    @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.1rem;
    }

    &_buttons {
        display: flex;
        place-items: center;
        gap: 10px;
        background-color: #fff;
        box-shadow: 0 0 2px #9e9e9e;
        border-radius: 3px;
        width: fit-content;

        button {
            cursor: pointer;
            border: none;
            background-color: transparent;
            display: flex;
            padding: 4px 6px;
            transition: 0.2s ease-in-out;

            &:hover {
                background-color: rgba($color: #000, $alpha: 0.1);
            }
        }

        div:first-child button {
            border-right: 1px solid #ddd;

            &:hover {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
            }
        }

        div:last-child button {
            border-left: 1px solid #ddd;

            &:hover {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }
        }
    }
}
</style>
