<template>
    <Card>
        <div class="info">
            <div class="info_text">
                <strong>Categoria:</strong>
                <span>{{ categoria_regras_rebaixa }}</span>
            </div>

            <div class="info_text">
                <strong>Regra Rebaixa:</strong>
                <span>{{ regra_rebaixa }}</span>
            </div>

            <div class="info_text" v-if="referencia">
                <strong>Referência:</strong>
                <span>{{ referencia }}</span>
            </div>

            <div class="info_text">
                <strong>Tempo Rebaixa:</strong>
                <span>{{ `${tempo_minimo} - ${tempo_maximo} dias` }}</span>
            </div>

            <Button texto="Editar" @click="editarRegra()" />
        </div>
    </Card>
</template>

<script>
import Card from '../UI/Card.vue';
import Button from '../UI/Button.vue';

export default {
    components: {
        Card,
        Button,
    },

    props: {
        categoria_regras_rebaixa: {
            required: true,
        },
        tempo_maximo: {
            required: true,
        },
        tempo_minimo: {
            required: true,
        },
        referencia: {
            required: true,
        },
        regra_rebaixa: {
            required: true,
        },
    },

    methods: {
        editarRegra() {
            const regras = {
                categoria_regras_rebaixa: this.categoria_regras_rebaixa,
                tempo_minimo: this.tempo_minimo,
                tempo_maximo: this.tempo_maximo,
                regra_rebaixa: this.regra_rebaixa,
                referencia: this.referencia,
            };

            this.$emit('editarRegra', regras);
        },
    },
};
</script>

<style lang="scss" scoped>
.info {
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;

    &_text {
        display: flex;
        justify-content: space-between;
        font-size: 0.9rem;
    }

    &_buttons {
        display: grid;
        grid-template-columns: 1fr 1.2fr;
        gap: 15px;
    }
}
</style>
