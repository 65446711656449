<template>
    <div class="search">
        <input type="text" :placeholder="texto" @input="updateInput($event)" />
    </div>
</template>

<script>
export default {
    props: {
        texto: {
            required: true,
            type: String,
        },
    },

    methods: {
        updateInput(event) {
            this.$emit('changeInput', event.target.value)
        }
    }
};
</script>

<style lang="scss" scoped>
.search {
    & input {
        width: 100%;
        padding: 10px;
        height: 40px;
        line-height: 40px;
        background-color: #fff;
        color: #363636;
        border-radius: 4px;
        border: none;
        box-shadow: 0 0 3px #696969;
        font-size: 1.1rem;
    }
}
</style>
