<template>
    <div class="title">
        <h1>{{ titulo }}</h1>
    </div>
</template>

<script>
export default {
    props: {
        titulo: {
            required: true,
        },
    },
};
</script>

<style scoped lang='scss'>
.title {
    text-align: center;
    color: #676767;
    letter-spacing: .5px;
    margin-bottom: 50px;

    @media (min-width: 768px) {
        font-size: 1.4rem;
        margin-bottom: 80px;
    }
}
</style>
