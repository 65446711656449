<template>
    <div class="button">
        <button :class="[isRemove ? 'remove' : 'edit']">{{ texto }}</button>
    </div>
</template>

<script>
export default {
    props: {
        texto: {
            required: true,
        },
        isRemove: {
            type: Boolean
        }
    },
};
</script>

<style lang="scss" scoped>
.button {
    & button {
        border: none;
        width: 100%;
        border-radius: 5px;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        color: #fff;
        transition: 0.2s ease-in-out;
        font-size: 1.1rem;

        &.remove {
            background-color: #eb3434;

            &:hover {
                background-color: darken($color: #eb3434, $amount: 20);
            }
        }

        &.edit {
            background-color: #1e8fff;

            &:hover {
                background-color: darken($color: #1e8fff, $amount: 20);
            }
        }
    }
}
</style>
