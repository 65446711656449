<template>
    <Overlay @fechaOverlay="$emit('fechaOverlay')">
        <Card>
            <form class="form" @submit.prevent="salvaRegra()">
                <div>
                    <label for="categoria_regras_rebaixa">Categoria:</label>
                    <input type="text" id="categoria_regras_rebaixa" name="categoria_regras_rebaixa" v-model="categoria_regras_rebaixa" />
                </div>

                <div>
                    <label for="regra_rebaixa">Regra:</label>
                    <select name="regra_rebaixa" id="regra_rebaixa" @change="regraHandler">
                        <option v-for="tipo in tipos" :key="tipo.regra" :value="tipo.regra">
                            {{ tipo.regra }}
                        </option>
                    </select>
                </div>

                <div v-if="referencias">
                    <label for="referencia">Referência:</label>
                    <select name="referencia" id="referencia" @change="referenciaHandler">
                        <option v-for="referencia in referencias" :key="referencia.referencia" :value="referencia.referencia">
                            {{ referencia.referencia }}
                        </option>
                    </select>
                </div>

                <div>
                    <div :class="{ error: tempoMinimoInvalido }">
                        <label for="tempo_minimo">Início:</label>
                        <input
                            type="number"
                            id="tempo_minimo"
                            name="tempo_minimo"
                            v-model="tempoMinimo"
                            min="0"
                            max="75"
                        />
                    </div>

                    <div :class="{ error: tempoMaximoInvalido }">
                        <label for="tempo_maximo">Fim:</label>
                        <input
                            type="number"
                            id="tempo_maximo"
                            name="tempo_maximo"
                            v-model="tempoMaximo"
                            min="0"
                            max="75"
                        />
                    </div>
                </div>

                <small class="form_error-msg" v-if="categoriaInvalida">Categoria não pode estar vazio.</small>
                <small class="form_error-msg" v-if="tempoMinimoInvalido">Tempo Mínimo não pode estar vazio.</small>
                <small class="form_error-msg" v-if="tempoMaximoInvalido">Tempo Máximo não pode estar vazio.</small>

                <Button texto="Salvar" />
            </form>
        </Card>
    </Overlay>
</template>

<script>
import Overlay from '../UI/Overlay.vue';
import Card from '../UI/Card.vue';
import Button from '../UI/Button.vue';

export default {
    components: {
        Overlay,
        Card,
        Button,
    },

    props: {
        tipos: {
            required: true,
        },
        referencias: {
            required: true,
        },
    },

    data() {
        return {
            tempoMinimoInvalido: false,
            tempoMaximoInvalido: false,
            categoriaInvalida: false,
            regraRebaixa: '',
            categoria_regras_rebaixa: '',
            referencia: '',
            tempoMinimo: '',
            tempoMaximo: '',
        };
    },

    watch: {
        tempoMinimo(newValue) {
            if (newValue != '') {
                this.tempoMinimoInvalido = false;
            }
        },
        tempoMaximo(newValue) {
            if (newValue != '') {
                this.tempoMaximoInvalido = false;
            }
        },
        categoria_regras_rebaixa(newValue) {
            if (newValue != '') {
                this.categoriaInvalida = false;
            }
        },
    },

    created() {
        this.regraRebaixa = this.tipos[0].regra;
        this.referencia = this.referencias[0].referencia;
    },

    methods: {
        regraHandler(event) {
            this.regraRebaixa = event.target.value;
        },

        referenciaHandler(event) {
            this.referencia = event.target.value;
        },

        salvaRegra() {
            if (this.validaRegra() == false) return;

            const regra = {
                categoria_regras_rebaixa: this.categoria_regras_rebaixa,
                tempo_minimo: this.tempoMinimo,
                tempo_maximo: this.tempoMaximo,
                regra_rebaixa: this.regraRebaixa,
                referencia: this.referencia,
            };

            this.$emit('salvaRegra', regra);
        },

        validaRegra() {
            if (this.tempoMinimo == '') {
                this.tempoMinimoInvalido = true;
                return false;
            }

            if (this.tempoMaximo == '') {
                this.tempoMaximoInvalido = true;
                return false;
            }

            if (this.categoria_regras_rebaixa == '') {
                this.categoriaInvalida = true;
                return false;
            }

            return true;
        },
    },
};
</script>

<style lang="scss" scoped>
.checkbox {
    display: block;

    p {
        font-size: 1.2rem;
        margin-bottom: 20px;
    }

    &_categorias {
        display: grid;
        gap: 15px;
        font-size: 1rem;
        height: 250px;
        overflow-y: auto;
        overflow-x: hidden;

        @media (min-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    input {
        width: auto;
    }

    &_wrap {
        justify-content: unset;
    }
}
</style>
